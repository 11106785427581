import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Tooltip, FormGroup, Label, Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Alert } from 'reactstrap';
import { MdArrowBack, MdDragIndicator, MdDragHandle } from "react-icons/md";
import * as Enterprise from '../service/Enterprise'
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import GlobalData from '../helpers/GlobalData';
import Constants from '../helpers/Constants';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import * as Utilities from '../helpers/Utilities';
// import * as UserService from '../service/User'
import * as PermissionService from '../service/Permission'
import ReCAPTCHA from "react-google-recaptcha";
// import * as EnterpriseMenuService from '../service/EnterpriseMenu';
import moment from 'moment';

class CreateAccount extends Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  
  loading = () =>   <div className="page-laoder">
    <div> 
      <Loader type="Oval" color="#ed0000" height={50} width={50}/>  
      <div className="loading-label">Loading.....</div>
      </div>
  </div> 

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      secureTextEntry:true,
      errorUsername: '',
      errorPassword: '',
      errorMsg: '',
      classDisplay: 'no-display',
      type: 'password',
      score: 'null',
      showEdit:true,
      userInfo: {FirstName: "", SurName: "", Email: "", Password: ""},
      storeInfo: this.props.storeInfo,
      createStoreError: false,
      createStoreMessage: '',
      isCheckboxChecked: false,
    };

  }

  SaveData = async( ) => {
    var response = await Enterprise.Create(this.state.storeInfo)
   
    
    if (response.Message === undefined) {
      const { cookies } = this.props;
      cookies.set(Constants.Cookies.USER_EMAIL, this.state.storeInfo.Email , {domain:"superbutler.ai"}); // set the cookie
      cookies.set(Constants.Cookies.USER_PASSWORD, this.state.storeInfo.Password, {domain:"superbutler.ai"}); // set the cookie
	  
	  cookies.set("test1", this.state.storeInfo.Email , {domain:".superbutler.ai"}); // set the cookie
      cookies.set("etst2", this.state.storeInfo.Password, {domain:"superbutler.ai"}); // set the cookie
	  cookies.set("etst3", this.state.storeInfo.Password, [{path:'/'},{domain:".superbutler.ai"}]); // set the cookie
	  cookies.set("etst4", this.state.storeInfo.Password, [{path:'/'},{domain:"superbutler.ai"}]); // set the cookie
	  
	  cookies.set("test5", this.state.storeInfo.Password, [{ path: GlobalData.enterprise_data.Supermeal_dev.backofficeUrl },{domain:"superbutler.ai"}]); // set the cookie
	    
	  cookies.set("test6", this.state.storeInfo.Password, [{path: GlobalData.enterprise_data.Supermeal_dev.backofficeUrl },{domain:".superbutler.ai"}]); // set the cookie
	    cookies.set("test7", this.state.storeInfo.Password, { path: GlobalData.enterprise_data.Supermeal_dev.backofficeUrl }); // set the cookie
      document.cookie = 'test8=' +this.state.storeInfo.Password + ';path=/;domain=.superbutler.ai';

      window.location.href = GlobalData.enterprise_data.Supermeal_dev.backofficeUrl;
      return;
    }

    this.setState({isSaving: false, createStoreError: true, createStoreMessage: response.Message})
    
  
  }

  createStore = (event, values) => {

    if (this.state.isSaving) return;
    this.setState({ isSaving: true, createStoreError: false, createStoreMessage: ''})

    var storeInfo = this.state.storeInfo;
    storeInfo.FirstName = values.firstName;
    storeInfo.SurName = values.surname;
    storeInfo.Email = values.email;
    storeInfo.Password = values.password;

    setTimeout(() => {
      this.SaveData();  
    }, 5000);
  }
  
  showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
      type: this.state.type === 'password' ? 'text' : 'password'
    })  
  }
   captchaChange =  (value) => {
    console.log("Captcha value:", value);
    this.setState((prevState) => ({
      isCheckboxChecked: !prevState.isCheckboxChecked,
    }));
  }
  

  handleChangeValue = (value) => {

    this.state.userInfo.Email = value;
    this.setState({createStoreError: false});

  }

  componentDidMount() {
    
  }

  render() {
    const { isCheckboxChecked } = this.state;
    return (
      <div  className="align-items-center create-acc-wrap" >
        <div className="card pb-relative" style={{minHeight:450}}>
          <div className=" p-4" >
          <h4 className='mb-4'>Create a Superbutler account.</h4>
          <AvForm onValidSubmit={this.createStore}>
         <div className='row'>
          <div className='col-lg-6'>
          <AvField name="firstName" placeholder="First Name" type="text"  value={this.state.userInfo.FirstName} onChange={(e) => this.state.userInfo.FirstName = e.target.value} 
                      validate={{
                        required: { value: this.props.isRequired, errorMessage: 'This is a required field' },
                      }}
          
          />
          </div>
          <div className='col-lg-6'>
          <AvField name="surname" placeholder="Surname" type="text"  value={this.state.userInfo.SurName} onChange={(e) => this.state.userInfo.SurName = e.target.value} 
                      validate={{
                        required: { value: this.props.isRequired, errorMessage: 'This is a required field' },
                      }}
          
          />
          </div>

         </div>
              <AvField name="email" placeholder="Email (This is your username)" type="email" value={this.state.userInfo.Email} onChange={(e) =>this.handleChangeValue(e.target.value)} 
                          validate={{
                            required: { value: this.props.isRequired, errorMessage: 'This is a required field' },
                            email: { value: false, errorMessage: 'email' },
                          }}
              />
            <div className='error w-100 mb-3'> {this.state.createStoreError ? this.state.createStoreMessage : ''} </div>
            <div className='row'>
                <div className='form-group col-md-12'>
                  {/* <label>Phone number</label> */}
                <div className='custom-ph-no'>
                    <PhoneInput
                    autocompleteSearch
                    enableSearch
                    inputProps={{
                      autoFocus: true,
                      searchPlaceholder:"Phone number"
                    }}
                      country={'us'}
                      value={""} 
                      onChange={""}
                      onFocus={""}
                      placeholder={"Phone number"}
                      // onChange={phone => this.setState({ mobOne: phone, mobError: false })}
                    />
                    </div>
                </div>
            </div>
    
       <div className='row'>
          <div className='col-lg-6'>
          <AvField name="password" placeholder="Password" type={this.state.type}  value={this.state.userInfo.Password} onChange={(e) => this.state.userInfo.Password = e.target.value}  
            validate={{
              required: { value: this.props.isRequired, errorMessage: 'This is a required field' },
            }}
          />
          <span className="password__show" onClick={this.showHide}> <span className={this.state.type === 'password' ? 'fa fa-eye-slash ' : 'fa fa-eye '}></span></span>
          </div>
          <div className='col-lg-6'>
          <AvField name="cPassword" placeholder="Re-type Password" type={this.state.type} 
          validate={{
            required: { value: this.props.isRequired, errorMessage: 'This is a required field' },
            match: { value: 'password', errorMessage: 'Confirm Password does not match' },
          }}
          />
          </div>
          <div className='col-md-12'>     
                    <ReCAPTCHA sitekey={GlobalData.enterprise_data.Supermeal_dev.RECaptchasitekey} onChange={this.captchaChange}/>
          </div>
         </div>
          
      
          <div className="d-flex justify-content-end align-items-center  px-4 btn-store-bottom w-100">
           <a className='mr-auto cursor-pointer d-flex align-items-center' onClick={()=>this.props.goToStep(2)}><MdArrowBack size={20}  className="mr-2"/>Back</a> 
          {/* <Button className='mr-2 px-3' color='secondary'>Cancel</Button> */}
        
          <Button disabled={!isCheckboxChecked} className='px-3' color="primary">
            
            {this.state.isSaving ? <span className="comment-loader"><Loader type="Oval" color="#fff" height={22} width={22} /></span> :  "Create"} </Button>
            
          </div>
        
        </AvForm>
          </div>
          </div>


          {this.state.isSaving ? <div className='store-loader'>
            <div>
            <figure>
    <div class="dot white"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
</figure>
</div>
<div className='s-title'>Preparing your hotel dashboard! Please wait...</div>
</div>:  ""}
             
      </div>
    );
  }
}

export default withCookies(CreateAccount);
