//==========================================================
// Web Service BaseURL

import Constants from "./Constants";

//==========================================================

const TimeZone = {
    ukTimeZone: "Europe/London",
    pkTimeZone: "Asia/Karachi",
	aeTimeZone: "Asia/Dubai"
};




// const BaseURLs = {
//      coreApiURL : "https://api.test.supermeal.co.uk/api",
//      baseImageURL: "https://cdn.superme.al/s/uk",
//      baseURL: "https://test.supermeal.co.uk",
//      clientId: "postman",
//      clientSecret : "test123",
// 	 distanceUnit: "mile(s)",
// 	countryCode: "+44",
// 	timeZone : TimeZone.ukTimeZone,
//	DateFormat:"DD/MM/YYYY",
//     currencySymbol: "£",
// 	SupportEmail:'Support@supermeal.co.uk',
// 	GoogleAPIkEY: 'AIzaSyBfpBhe3zDJF1n9-kEP94rDXDXgz2YXeSA',
// 	Platform:'core' //superShoply //core
//  };
 
// const BaseURLs = {
//    coreApiURL : "https://api.staging.supermeal.ae/api",
//    baseImageURL: "https://cdn.superme.al/s/ae",
//    baseURL: "https://staging.supermeal.ae",
//    clientId: "postman",
//     clientSecret : "test123",
//	currencySymbol : "AED ",
//	distanceUnit: "km",
//    countryCode: "+971",
//    DateFormat:"DD/MM/YYYY",
//	timeZone : TimeZone.aeTimeZone,
//	SupportEmail:'Support@supermeal.ae',
//	GoogleAPIkEY: 'AIzaSyDylC4O2oYlOnF4NLtj9PYbzTJe6tbVNBw',
//	Platform:'core' //superShoply //core

// };

var countryConfig = JSON.parse(localStorage.getItem(Constants.Session.USER_OBJECT)) != undefined ? JSON.parse(localStorage.getItem(Constants.Session.USER_OBJECT)).Enterprise.Country : null

 const BaseURLs = {
    //   coreApiURL : "https://localhost:44344//api",
    coreApiURL : "https://api.test.superbutler.app/api",
    baseImageURL: "https://cdn.superme.al/s/butler",
    baseURL: "https://api.test.superbutler.app",
    clientId: "postman",
    clientSecret : "test123",
    distanceUnit: "mile(s)",
   countryCode: "+44",
   DateFormat:"DD/MM/YYYY", 
 	  timeZone : TimeZone.ukTimeZone,
      backofficeUrl : "https://testbackoffice.superbutler.ai/",
      currencySymbol: "£",
     SupportEmail:'Support@supershoply.com',
     GoogleAPIkEY: 'AIzaSyB7FZe7D20kpyiNs55Ab1WU6J1Bl6Y7Nds',
     Platform:'superShoply' //superShoply //core
  };

 export const parentCountryCode = "+44"

const GlobalData = {

    enterprise_data: {

        Supermeal_dev: {
            isUk: true,
            coreApiURL : BaseURLs.coreApiURL,
            clientApi : { ClientId: BaseURLs.clientId, ClientSecret: BaseURLs.clientSecret},
            baseUrl: BaseURLs.baseURL, 
            backofficeUrl: BaseURLs.backofficeUrl,
            baseImageUrl: countryConfig != null ? countryConfig.CDNUrl : BaseURLs.baseImageURL, //BaseURLs.baseImageURL,
            currency: countryConfig !=null ? countryConfig.CurrencySybmol : '',   //BaseURLs.currencySymbol,
            decimalPlaces: countryConfig !=null ? countryConfig.DecimalPlaces : '', //2,
            csvSeperator: '^',
            distanceUnit: BaseURLs.distanceUnit,
            timezone: countryConfig !=null ? countryConfig.TimeZone : '', //BaseURLs.timeZone,
            countryCode: countryConfig !=null ? countryConfig.CallingCode : '', //BaseURLs.countryCode,
            dateFormat: countryConfig !=null ? countryConfig.DateTimeFormat : '', //BaseURLs.DateFormat,
            DeliveryCharges: "0",
            DeliveryTime: "45",
            MinimumDeliveryOrder: "10",
            zoneLimit: 5,
			Google_Api_Key: BaseURLs.GoogleAPIkEY,
            SupportEmail: BaseURLs.SupportEmail,
            Google_Lat_Long: [51.507351,-0.127758],
            Delivery_Zone_Color_Palette : ["#27ae60","#3498db","#f1c40f","#e74c3c","#8e44ad"],
            Campaign_Logo_Image_Width_Height : ["175","55"],
            Campaign_Main_Banner_Width_Height : ["600","318"],
            Campaign_Sub_Banner_Width_Height : ["1920","150"],
            Campaign_App_Sub_Banner_Width_Height : ["600","100"],
            Campaign_Food_Image_Width_Height : ["750","750"],
            Campaign_Background_Image_Width_Height : ["1920","1080"],
            Campaign_App_Background_Image_Width_Height : ["1000","1000"], 
            Category_Image_Width_Height : ["400","400"], 
            Product_Image_Width_Height : ["500","500"],
            Enterprise_Bank_Details : "Account Name:;Account Number:;Bank Name:;Branch Name:;Branch Address:;Swift Code:;",
            Item_Filters : "Brand::;Tags::;Dietry::;VideoURL::;",
            Session_Expire_In_Hours: 24,
            Platform: BaseURLs.Platform,
            PageSize: 20,
            RECaptchasitekey:'6LfpcjgpAAAAABuyx4Jgn_Vchvko6ciWwz8Dl_XC',

        }

    }

}

export default GlobalData
