import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Tooltip, FormGroup, Label, Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Alert } from 'reactstrap';
class CreateAccount extends Component {
  state = { interval: null }
  
  loading = () =>   <div className="page-laoder">
    <div> 
      <Loader type="Oval" color="#ed0000" height={50} width={50}/>  
      <div className="loading-label">Loading.....</div>
      </div>
  </div> 

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      secureTextEntry:true,
      errorUsername: '',
      errorPassword: '',
      errorMsg: '',
      classDisplay: 'no-display',
      type: 'password',
      score: 'null',
      showEdit:true,
    };
   

  }

 showEditFun=()=>{
this.setState({
  showEdit:!this.state.showEdit
})

 }
  render() {
    return (
      <div  className="align-items-center" >
        <div className="card">
          <div className=" p-4" >
          <h4 className='mb-2'>Create a Superbutler account.</h4>
          <p>One last step before starting your free trial.</p>
            <div className="d-flex flex-column mb-3">
              <a className='btn social-btn btn-secondary py-2 font-16 mb-4' onClick={()=>this.props.goToStep(5) + this.props.onClick(90)}><span><i className="far fa-envelope mr-2 font-16" aria-hidden="true"></i></span> <span>Continue with Email</span></a>
              <a className='btn social-btn btn-secondary py-2 font-16 mb-4'><span><i className="fab fa-apple mr-2 font-20" aria-hidden="true"></i></span> <span>Continue with Apple</span></a>
              <a className='btn social-btn btn-secondary py-2 font-16 mb-4'><span><i className="fab fa-facebook-square mr-2 font-18 fb" aria-hidden="true"></i></span> <span> Continue with Facebook</span></a>
              <a className='btn social-btn btn-secondary py-2 font-16 mb-4'><span><i className="fab fa-google mr-2 font-16" aria-hidden="true"></i></span> <span>Continue with Google</span></a>
            </div>
            <p>
            By proceeding, you agree to the <a href="#" className='text-primary cursor-pointer'>Terms and Conditions</a> and <a href="#" className='text-primary cursor-pointer'>Privacy Policy</a> 
          </p>
          <p class="help-link">
            Already have a Shopify account?
            <a href="#" className='text-primary cursor-pointer ml-2'>Log in</a>
          </p>
          </div>
          </div>

      </div>
    );
  }
}

// var LoadingDiv = React.createClass({
//   render: function() {
//       return (
//         ''
//       );
//   }
// });

/*const LoadingDiv = () => (
  <div className='modal'>
        Hello, World!
    </div>
  )*/

export default CreateAccount;
